import actions from './actions';

const { 
    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN, MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS, MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR,
    MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN, MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS, MAINTENANCE_DASHBOARD_TICKET_DATA_ERR,
    MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN, MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS, MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR,
    MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN, MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS, MAINTENANCE_DASHBOARD_REGION_DATA_ERR,
    MAINTENANCE_DEPARTMENT_DATA_BEGIN, MAINTENANCE_DEPARTMENT_DATA_SUCCESS, MAINTENANCE_DEPARTMENT_DATA_ERR,
    MAINTENANCE_LIST_DATA_BEGIN, MAINTENANCE_LIST_DATA_SUCCESS, MAINTENANCE_LIST_DATA_ERR,
    MAINTENANCE_LEAD_ASSIGN_DATA_BEGIN, MAINTENANCE_LEAD_ASSIGN_DATA_SUCCESS, MAINTENANCE_LEAD_ASSIGN_DATA_ERR,  MAINTENANCE_LEAD_ASSIGN_DATA_RESET,

    GET_ASSIGNED_MAINTENANCE_LIST_DATA_BEGIN, GET_ASSIGNED_MAINTENANCE_LIST_DATA_SUCCESS, GET_ASSIGNED_MAINTENANCE_LIST_DATA_ERR,
    UPDATE_MAINTENANCE_STATUS_DATA_BEGIN, UPDATE_MAINTENANCE_STATUS_DATA_SUCCESS, UPDATE_MAINTENANCE_STATUS_DATA_ERR, UPDATE_MAINTENANCE_STATUS_DATA_RESET,
    GET_MAINTENANCE_STATUS_HISTORY_DATA_BEGIN, GET_MAINTENANCE_STATUS_HISTORY_DATA_SUCCESS, GET_MAINTENANCE_STATUS_HISTORY_DATA_ERR,

    MAINTENANCE_REPORT_DATA_BEGIN, MAINTENANCE_REPORT_DATA_SUCCESS, MAINTENANCE_REPORT_DATA_ERR,
    MAINTENANCE_REPORT_SAVE_DATA_BEGIN, MAINTENANCE_REPORT_SAVE_DATA_SUCCESS, MAINTENANCE_REPORT_SAVE_DATA_ERR, 
    MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN, MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS, MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR, 
} = actions;

const initialState = { data: [], loading: false, error: null, };
const initialStateDashboard = { data: '', start:null, end:null, loading: false, error: null, };


const maintenanceDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case MAINTENANCE_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceDashbaordSummaryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case MAINTENANCE_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceDepartmentReducer = (state = initialState, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_DEPARTMENT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_DEPARTMENT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case MAINTENANCE_DEPARTMENT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const maintenanceListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceLeadAssignReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_LEAD_ASSIGN_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_LEAD_ASSIGN_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_LEAD_ASSIGN_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case MAINTENANCE_LEAD_ASSIGN_DATA_RESET:
            return initialState
        default:
            return state;
    }
};


const maintenanceEmployeeBasedListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ASSIGNED_MAINTENANCE_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_ASSIGNED_MAINTENANCE_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_ASSIGNED_MAINTENANCE_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const updateMaintenanceStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_MAINTENANCE_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_MAINTENANCE_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_MAINTENANCE_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_MAINTENANCE_STATUS_DATA_RESET:
            return initialState

        default:
            return state;
    }
};


const maintenanceStatusHisoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_MAINTENANCE_STATUS_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_MAINTENANCE_STATUS_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_MAINTENANCE_STATUS_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const maintenanceReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



export { 
    maintenanceDashbaordLeadSourceReducers, maintenanceDashboardCategoryReducers, maintenanceDashboardTicketReducers, maintenanceDashbaordSummaryReducers, 
    maintenanceDashbaordRegionReducers, maintenanceDepartmentReducer,  
    maintenanceListReducers, maintenanceLeadAssignReducers,
    maintenanceEmployeeBasedListReducers,updateMaintenanceStatusReducers,maintenanceStatusHisoryReducers,
    maintenanceReportReducers, maintenanceReportSaveReducers,maintenanceReportDownloadReducers };
