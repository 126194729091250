const actions = {
    CATEGORY_DATA_BEGIN : 'CATEGORY_DATA_BEGIN',
    CATEGORY_DATA_SUCCESS : 'CATEGORY_DATA_SUCCESS',
    CATEGORY_DATA_ERR : 'CATEGORY_DATA_ERR',
    
    BRAND_DATA_BEGIN : 'BRAND_DATA_BEGIN',
    BRAND_DATA_SUCCESS : 'BRAND_DATA_SUCCESS',
    BRAND_DATA_ERR : 'BRAND_DATA_ERR',

    STORE_LIST_DATA_BEGIN : 'STORE_LIST_DATA_BEGIN',
    STORE_LIST_DATA_SUCCESS : 'STORE_LIST_DATA_SUCCESS',
    STORE_LIST_DATA_ERR : 'STORE_LIST_DATA_ERR',


    STORE_PINCODE_DATA_BEGIN : 'STORE_PINCODE_DATA_BEGIN',
    STORE_PINCODE_DATA_SUCCESS : 'STORE_PINCODE_DATA_SUCCESS',
    STORE_PINCODE_DATA_ERR : 'STORE_PINCODE_DATA_ERR',
    STORE_PINCODE_DATA_RESET:'STORE_PINCODE_DATA_RESET',

    MODEL_LIST_DATA_BEGIN : 'MODEL_LIST_DATA_BEGIN',
    MODEL_LIST_DATA_SUCCESS : 'MODEL_LIST_DATA_SUCCESS',
    MODEL_LIST_DATA_ERR : 'MODEL_LIST_DATA_ERR',

    STOCK_EMPLOYEE_LIST_DATA_BEGIN : 'STOCK_EMPLOYEE_LIST_DATA_BEGIN',
    STOCK_EMPLOYEE_LIST_DATA_SUCCESS : 'STOCK_EMPLOYEE_LIST_DATA_SUCCESS',
    STOCK_EMPLOYEE_LIST_DATA_ERR : 'STOCK_EMPLOYEE_LIST_DATA_ERR',

    MAINTENANCE_EMPLOYEE_LIST_DATA_BEGIN : 'MAINTENANCE_EMPLOYEE_LIST_DATA_BEGIN',
    MAINTENANCE_EMPLOYEE_LIST_DATA_SUCCESS : 'MAINTENANCE_EMPLOYEE_LIST_DATA_SUCCESS',
    MAINTENANCE_EMPLOYEE_LIST_DATA_ERR : 'MAINTENANCE_EMPLOYEE_LIST_DATA_ERR',

    VENDOR_EMPLOYEE_LIST_DATA_BEGIN : 'VENDOR_EMPLOYEE_LIST_DATA_BEGIN',
    VENDOR_EMPLOYEE_LIST_DATA_SUCCESS : 'VENDOR_EMPLOYEE_LIST_DATA_SUCCESS',
    VENDOR_EMPLOYEE_LIST_DATA_ERR : 'VENDOR_EMPLOYEE_LIST_DATA_ERR',

    categoryDataBegin: () => {
        return {
            type: actions.CATEGORY_DATA_BEGIN,
        };
    },

    categoryDataSuccess: (data) => {
        return {
            type: actions.CATEGORY_DATA_SUCCESS,
            data,
        };
    },

    categoryDataErr: (err) => {
        return {
            type: actions.CATEGORY_DATA_ERR,
            err,
        };
    },

    brandDataBegin: () => {
        return {
            type: actions.BRAND_DATA_BEGIN,
        };
    },

    brandDataSuccess: (data) => {
        return {
            type: actions.BRAND_DATA_SUCCESS,
            data,
        };
    },

    brandDataErr: (err) => {
        return {
            type: actions.BRAND_DATA_ERR,
            err,
        };
    },

    storeListDataBegin: () => {
        return {
            type: actions.STORE_LIST_DATA_BEGIN,
        };
    },

    storeListDataSuccess: (data) => {
        return {
            type: actions.STORE_LIST_DATA_SUCCESS,
            data,
        };
    },

    storeListDataErr: (err) => {
        return {
            type: actions.STORE_LIST_DATA_ERR,
            err,
        };
    },

    storePincodeDataBegin: () => {
        return {
            type: actions.STORE_PINCODE_DATA_BEGIN,
        };
    },

    storePincodeDataSuccess: (data) => {
        return {
            type: actions.STORE_PINCODE_DATA_SUCCESS,
            data,
        };
    },

    storePincodeDataErr: (err) => {
        return {
            type: actions.STORE_PINCODE_DATA_ERR,
            err,
        };
    },

    storePincodeDataReset : () => {
        return {
            type: actions.STORE_PINCODE_DATA_RESET
        };
    },

    modelListDataBegin: () => {
        return {
            type: actions.MODEL_LIST_DATA_BEGIN,
        };
    },

    modelListDataSuccess: (data) => {
        return {
            type: actions.MODEL_LIST_DATA_SUCCESS,
            data,
        };
    },

    modelListDataErr: (err) => {
        return {
            type: actions.MODEL_LIST_DATA_ERR,
            err,
        };
    },

    stockEmployeeListDateBegin: () => {
        return {
            type: actions.STOCK_EMPLOYEE_LIST_DATA_BEGIN,
        };
    },

    stockEmployeeListDataSuccess: (data) => {
        return {
            type: actions.STOCK_EMPLOYEE_LIST_DATA_SUCCESS,
            data,
        };
    },

    stockEmployeeListDataErr: (err) => {
        return {
            type: actions.STOCK_EMPLOYEE_LIST_DATA_ERR,
            err,
        };
    },

    maintenanceEmployeeListDateBegin: () => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_LIST_DATA_BEGIN,
        };
    },

    maintenanceEmployeeListDateSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_LIST_DATA_SUCCESS,
            data,
        };
    },

    maintenanceEmployeeListDateErr: (err) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_LIST_DATA_ERR,
            err,
        };
    },

    vendorEmployeeListDateBegin: () => {
        return {
            type: actions.VENDOR_EMPLOYEE_LIST_DATA_BEGIN,
        };
    },

    vendorEmployeeListDataSuccess: (data) => {
        return {
            type: actions.VENDOR_EMPLOYEE_LIST_DATA_SUCCESS,
            data,
        };
    },

    vendorEmployeeListDataErr: (err) => {
        return {
            type: actions.VENDOR_EMPLOYEE_LIST_DATA_ERR,
            err,
        };
    },
    
};

export default actions;
