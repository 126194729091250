import React, { useEffect } from 'react';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { TopMenuStyle } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestTypes } from '../redux/agent/actionCreator';



function TopMenu() {

    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const fullPath = pathName.split('/');
    
    const addParentActive = (event) => {
        document.querySelectorAll('.parent').forEach((element) => {
            element.classList.remove('active');
        });
        const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
        const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
        if (!megaMenu) {
            event.currentTarget.closest('ul').previousSibling.classList.add('active');
            if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
        } else {
            event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
        }
    };

    const loginDetails = useSelector(state => state.auth.loginData);

    const agentStatus = useSelector((state) => state.agentStatus.data);
   
    
    const dispatch = useDispatch();
    
    
    useEffect(()=> {
        const allowURL = ['/admin/datamanagement','/admin/agent'];
        if(allowURL.includes(path))
            dispatch(getRequestTypes())

    },[dispatch,path])

    const getRequestTypesDetails = useSelector((state) => state.getRequestTypes.data);
    

    const typevalue = []; //'complaint-register', 'demo-register'

    return (
        <TopMenuStyle>
            <div className="admin-top-menu">
                
                { fullPath[2] === 'sales' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/sales_list`} className={fullPath[3] === 'sales_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Sales List
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}
                
                { fullPath[2] === 'customer' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/customer_list`} className={fullPath[3] === 'customer_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Customers
                                </Link>
                            </li>
                            {/* <li>
                                <Link to={`${path}/customer_group`} className={fullPath[3] === 'customer_group' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Customer Group
                                </Link>
                            </li> */}
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'employee' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'employee_list' || fullPath[3] === 'isd_employee_list') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="users" /> Employee
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/employee_list`} onClick={addParentActive}>
                                            Pai Employee
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/isd_employee_list`} onClick={addParentActive}>
                                            ISD Employee
                                        </NavLink>
                                    </li>
                                    {loginDetails?.loginType && loginDetails?.loginType === 'superadmin' && (
                                    <li>
                                        <NavLink to={`${path}/custom_employee_list`} onClick={addParentActive}>
                                            Custom Employee
                                        </NavLink>
                                    </li>
                                    )}
                                </ul>
                            </li>
                            {/* <li>
                                <Link to={`${path}/employee_control`} className={fullPath[3] === 'employee_control' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Employee Control
                                </Link>
                            </li> */}
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'store' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/store_list`} className={fullPath[3] === 'store_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Store List
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'inventory' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/inventory_list`} className={fullPath[3] === 'inventory_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Inventory List
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/inventory_request`} className={fullPath[3] === 'inventory_request' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Inventory Request
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/search_product`} className={fullPath[3] === 'search_product' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Search Product
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'walkout' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li className="has-subMenu">
                                <Link to="#" > 
                                    <FeatherIcon icon="list" /> Walkout Data
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={{pathname: `${path}/un_assign_list`, state: { walkout_status: 'un-assign' }}} onClick={addParentActive}>
                                            Un-Assign Walkout List
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/assign_list`, state: { walkout_status: 'assign' }}} onClick={addParentActive}>
                                            Assigned Walkout List
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/close_list`, state: { walkout_status: 'closed' }}} onClick={addParentActive}>
                                            Closed List
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/list`, state: { walkout_status: 'all' }}} onClick={addParentActive}>
                                            All Walkout List
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'datamanagement' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'follow_demo_register' || fullPath[3] === 'follow_demo_happy' || fullPath[3] === 'follow_complaint_register') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Register Data List
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={{ pathname:`${path}/demo_register/sap`}} onClick={addParentActive} >
                                            Demo Register - SAP
                                        </NavLink>
                                    </li>
                                    
                                    { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0  && getRequestTypesDetails.object_list.map((value,key) => (value.menu_type === 'register' && !typevalue.includes(value.slug) ) && (
                                        <li key={'complaint-'+key}>
                                            <NavLink to={{ pathname:`${path}/follow/${value.slug}`, state:value}} onClick={addParentActive} >
                                                {value.title}
                                            </NavLink>
                                        </li>
                                    ))}
                                    
                                </ul>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'demo_happy_sap' || fullPath[3] === 'follow_demo_register' || fullPath[3] === 'follow_demo_happy' || fullPath[3] === 'follow_complaint_register') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Happy Data List
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/demo_happy`} onClick={addParentActive}>
                                            Demo Happy
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/demo_happy_sap`} onClick={addParentActive}>
                                            Demo Happy - SAP
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/complaint_happy`} onClick={addParentActive}>
                                            Complaint Happy
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/furniture_complaint_happy`} onClick={addParentActive}>
                                            Furniture Complaint Happy
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to={`${path}/legal_case_happy`} onClick={addParentActive}>
                                            Legal Case Happy
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/defective_unit_delivered_happy`} onClick={addParentActive}>
                                            Defective Unit Delivered Happy
                                        </NavLink>
                                    </li>
                                    
                                </ul>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'schedule_log' || fullPath[3] === 'priority_log' || fullPath[3] === 'sap_schedule_log' || fullPath[3] === 'sap_priority_log') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Follow up List
                                </Link>
                                <ul className="subMenu">

                                    <li className="has-subMenu-left" key={'scheduled-log'}>
                                        <Link to="#" className="parent">
                                            Scheduled Log  
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/schedule_log/complaint-register`} onClick={addParentActive} >
                                                    Complaint
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/schedule_log/demo-register`} onClick={addParentActive} >
                                                    Demo
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={{ pathname:`${path}/sap_schedule_log`}} onClick={addParentActive} >
                                                    Demo SAP
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/schedule_log/furniture-complaint-register`} onClick={addParentActive} >
                                                    Furniture Complaint 
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/schedule_log/defective-unit-delivered`} onClick={addParentActive} >
                                                    Defective Unit Delivered
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="has-subMenu-left" key={'priority-log'}>
                                        <Link to="#" className="parent">
                                            Priority Log
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/priority_log/complaint-register`} onClick={addParentActive} >
                                                    Complaint
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/priority_log/demo-register`} onClick={addParentActive} >
                                                    Demo
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/priority_log/furniture-complaint-register`} onClick={addParentActive} >
                                                    Furniture Complaint 
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink to={`${path}/priority_log/defective-unit-delivered`} onClick={addParentActive} >
                                                    Defective Unit Delivered
                                                </NavLink>
                                            </li>    
                                            
                                            <li>
                                                <NavLink to={{ pathname:`${path}/sap_priority_log`}} onClick={addParentActive} >
                                                    Demo SAP
                                                </NavLink>
                                            </li>
                                            
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'follow_demo_register' || fullPath[3] === 'follow_demo_happy' || fullPath[3] === 'follow_complaint_register') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Tele Sales Tab
                                </Link>
                                <ul className="subMenu">
                                    { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0  && getRequestTypesDetails.object_list.map((value,key) => value.menu_type === 'tele-sales' && (
                                        <li key={'tele'+key}>
                                            <NavLink to={{ pathname:`${path}/follow/${value.slug}`, state:value}} onClick={addParentActive} >
                                                {value.title}
                                            </NavLink>
                                        </li>
                                    ))}
                                    <li>
                                        <NavLink to={`${path}/accessories_tele_sales_sap`} onClick={addParentActive}>
                                            Accessories Tele Sales - SAP
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/mandatory_service`} onClick={addParentActive}>
                                            PAI Mandatory Service
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/abandoned_cart`} onClick={addParentActive}>
                                            Abandoned Cart
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#">
                                    <FeatherIcon icon="file-text" />   Upload Dump
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/upload_dump`} onClick={addParentActive}>
                                            Upload
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/upload_dump_status`} onClick={addParentActive}>
                                            Upload Status
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                           
                            <li className="has-subMenu">
                                <Link to="#">
                                    <FeatherIcon icon="file-text" /> Report
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={{pathname: `${path}/report_register`, state: { report_type: 'register' }}} onClick={addParentActive}>
                                            Register Data Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/report_happy`, state: { report_type: 'happy' }}} onClick={addParentActive}>
                                            Happy Data Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/report_follow`, state: { report_type: 'follow' }}} onClick={addParentActive}>
                                            Follow up Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: `${path}/report_tele`, state: { report_type: 'tele' }}} onClick={addParentActive}>
                                            Tele Sales Report
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'queries' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/purchase`} className={fullPath[3] === 'purchase' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Purchase Queries
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/purchase_follow`} className={fullPath[3] === 'purchase_follow' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Follow Purchase Queries
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'customercare' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>
                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'live_calls' || fullPath[3] === 'signin_signout' || fullPath[3] === 'call_report' || fullPath[3] === 'live_barging') ? 'active parent' : 'parent'} > 
                                    <FeatherIcon icon="list" /> Call Center Report 
                                </Link>
                                <ul className="subMenu">

                                    <li className="has-subMenu-left" key={'complaint-register-2'}>
                                        <Link to="#" className="parent">
                                            Live Calls
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/live_calls_self`} onClick={addParentActive} >
                                                    Marketing
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={{ pathname:`${path}/live_calls_blr_sip`}} onClick={addParentActive} >
                                                    BLR SIP
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/signin_signout`} onClick={addParentActive}>
                                            SignIn SignOut 
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/hours_activity_report`} onClick={addParentActive}>
                                            Hours Activity Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/call_report`} onClick={addParentActive}>
                                            Call Mointoring Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/call_barging_report`} onClick={addParentActive}>
                                            Call Barging Report
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/voip_incoming_call`} onClick={addParentActive}>
                                            VOIP Incoming Calls
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'auto_assign_walkout' || fullPath[3] === 'auto_assign_demo' || fullPath[3] === 'lead_agent_wise' ) ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Auto Assign Lead
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/auto_assign_walkout`} onClick={addParentActive}>
                                            Walkout
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/auto_assign_demo_register`} onClick={addParentActive}>
                                            Demo Register
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/auto_assign_demo_happy`} onClick={addParentActive}>
                                            Demo Happy
                                        </NavLink>
                                    </li>  
                                    <li>
                                        <NavLink to={`${path}/auto_assign_complaint_register`} onClick={addParentActive}>
                                            Complaint Register
                                        </NavLink>
                                    </li>   
                                    <li>
                                        <NavLink to={`${path}/auto_assign_complaint_happy`} onClick={addParentActive}>
                                            Complaint Happy
                                        </NavLink>
                                    </li>  
                                    <li>
                                        <NavLink to={`${path}/lead_agent_wise`} onClick={addParentActive}>
                                            Agent wise Leads
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>  */}
                            <li>
                                <Link to={`${path}/agent`} className={fullPath[3] === 'agent' ? 'active' :null} >
                                    <FeatherIcon icon="users" />Agent
                                </Link>
                            </li>
                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'setting_request_type' || fullPath[3] === 'setting_sub_request_type' || fullPath[3] === 'setting_break_details') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="settings" /> Setting
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/setting_request_type`} onClick={addParentActive}>
                                            Request Type
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/setting_sub_request_type`} onClick={addParentActive}>
                                            Sub Request Type
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/setting_break_details`} onClick={addParentActive}>
                                            Break Details
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'maintenance' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/list`} className={fullPath[3] === 'list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> List
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/follow`} className={fullPath[3] === 'follow' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Follow
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/employee`} className={fullPath[3] === 'employee' ? 'active' :null} >
                                    <FeatherIcon icon="users" /> Maintenance Employee
                                </Link>
                            </li>
                            {/* <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'mt_issues_type' || fullPath[3] === 'mt_department') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="settings" /> Setting
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/mt_department`} onClick={addParentActive}>
                                            Department
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/mt_issues_type`} onClick={addParentActive}>
                                            Type of Issues   
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'quality' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/mt_tickets`} className={fullPath[3] === 'mt_employee' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Quality Leads
                                </Link>
                            </li>
                            
                            <li className="has-subMenu">
                                <Link to="#" className={(fullPath[3] === 'mt_issues_type' || fullPath[3] === 'mt_department') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="settings" /> Setting
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/mt_department`} onClick={addParentActive}>
                                            Department
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/mt_issues_type`} onClick={addParentActive}>
                                            Type of Issues   
                                        </NavLink>
                                    </li>
                                    
                                </ul>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'warranty' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/purchase`} className={fullPath[3] === 'purchase' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Purchase 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/complaint`} className={fullPath[3] === 'complaint' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Complaint Register
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/complaint_happy`} className={fullPath[3] === 'complaint_happy' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Happy 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/priority`} className={fullPath[3] === 'priority' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Priority 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/scheduled`} className={fullPath[3] === 'scheduled' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Scheduled 
                                </Link>
                            </li>
                            
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>

                        </ul>
                    </>
                )}

                { fullPath[2] === 'out_of_warranty' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>

                            {/* <li>
                                <Link to={`${path}/purchase`} className={fullPath[3] === 'purchase' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Purchase 
                                </Link>
                            </li> */}

                            <li>
                                <Link to={`${path}/complaint`} className={fullPath[3] === 'complaint' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Complaint Register
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/complaint_happy`} className={fullPath[3] === 'complaint_happy' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Happy 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/priority`} className={fullPath[3] === 'priority' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Priority 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/scheduled`} className={fullPath[3] === 'scheduled' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Scheduled 
                                </Link>
                            </li>
                            
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>

                        </ul>
                    </>
                )}

                { fullPath[2] === 'escalation' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/list`} className={fullPath[3] === 'list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Escalation 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/scheduled`} className={fullPath[3] === 'scheduled' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Scheduled 
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/priority`} className={fullPath[3] === 'priority' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Priority 
                                </Link>
                            </li>
                            
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>

                        </ul>
                    </>
                )}

                { fullPath[2] === 'review' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={fullPath[3] === 'medium' && (fullPath[4] === 'google' || fullPath[4] === 'facebook' || fullPath[4] === 'instagram') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Medium List
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={{ pathname:`${path}/medium/google`}} onClick={addParentActive} >
                                            Google
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/medium/facebook`} onClick={addParentActive}>
                                            Facebook
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/medium/instagram`} onClick={addParentActive}>
                                            Instagram
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-subMenu">
                                <Link to="#" className={ fullPath[3] === 'medium' && (fullPath[4] === 'purchase-experience' || fullPath[4] === 'delivery-experience' || fullPath[4] === 'service-experience') ? 'active parent' : 'parent'} >
                                    <FeatherIcon icon="list" /> Follow List
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={{ pathname:`${path}/follow/purchase-experience`}} onClick={addParentActive} >
                                            Purchase Experience
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/follow/delivery-experience`} onClick={addParentActive}>
                                            Delivery Experience
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/follow/service-experience`} onClick={addParentActive}>
                                            Service Experience
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
 
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>

                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>

                        </ul>
                    </>
                )}

                { fullPath[2] === 'stock_defective' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/list`} className={fullPath[3] === 'list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Registered List
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/pending_list`} className={fullPath[3] === 'pending_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Pending List
                                </Link>
                            </li>
                            <li className="has-subMenu">
                                <Link to="#">
                                    <FeatherIcon icon="file-text" />   Upload Dump
                                </Link>
                                <ul className="subMenu">
                                    <li>
                                        <NavLink to={`${path}/upload_dump`} onClick={addParentActive}>
                                            Upload
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${path}/upload_dump_status`} onClick={addParentActive}>
                                            Upload Status
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Available Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'agent' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>
                            {agentStatus && agentStatus?.current_status === 'Available'  && (
                                <>
                                    <li>
                                        <Link to={`${path}/check_customer`} className={fullPath[3] === 'check_customer' ? 'active' :null} >
                                            <FeatherIcon icon="search" /> Customer
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${path}/search_reference`} className={fullPath[3] === 'search_reference' ? 'active' :null} >
                                            <FeatherIcon icon="search" />Referenece No
                                        </Link>
                                    </li>
                                    <li className="has-subMenu">
                                        <Link to="#" className={(fullPath[3] === 'purchase_enquiry' || fullPath[3] === 'assign_leads_purchase') ? 'active parent' : 'parent'} >
                                            <FeatherIcon icon="globe" />  Purchase Enquiry
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/assign_leads_purchase`} onClick={addParentActive}>
                                                    Purchase List
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/purchase_enquiry`} onClick={addParentActive}>
                                                    Add Purchase Enquiry
                                                </NavLink>
                                            </li>
                                            
                                        </ul>
                                    </li>
                                    {/* <li>
                                        <Link to={`${path}/change_call`} className={fullPath[3] === 'change_call' ? 'active' :null}>
                                            <FeatherIcon icon="phone-call" />  Change Call
                                        </Link>
                                    </li> */}

                                    <li className="has-subMenu">
                                        <Link to="#" className={(fullPath[3] === 'add_review' || fullPath[3] === 'review_list') ? 'active parent' : 'parent'} >
                                            <FeatherIcon icon="star" />  Review
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/add_review`} onClick={addParentActive}>
                                                    Add Review
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/review_list`} onClick={addParentActive}>
                                                    Review List
                                                </NavLink>
                                            </li>
                                            
                                        </ul>
                                    </li>


                                    {agentStatus?.lead_type === 'assign_leads'  && (
                                    <li className="has-subMenu">
                                        <Link to="#" className={(fullPath[3] === 'assign_leads_walkout' || fullPath[3] === 'pai_mandatory_service') ? 'active parent' : 'parent'} >
                                            <FeatherIcon icon="airplay" />  Assign Leads
                                        </Link>
                                        <ul className="subMenu">
                                            <li>
                                                <NavLink to={`${path}/assign_leads_walkout`} onClick={addParentActive}>
                                                    Walkout Leads
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`${path}/pai_mandatory_service`} onClick={addParentActive}>
                                                    PAI Mandatory Service
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink to={`${path}/abandoned_cart`} onClick={addParentActive}>
                                                    Abandoned Cart
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink to={`${path}/abandoned_cart_priority`} onClick={addParentActive}>
                                                    Abandoned Cart Priority
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink to={`${path}/accessories_tele_sales_sap`} onClick={addParentActive}>
                                                    Accessories Tele Sales - SAP
                                                </NavLink>
                                            </li>
                                            
                                            {getRequestTypesDetails && getRequestTypesDetails.object_list && getRequestTypesDetails.object_list.length > 0 && getRequestTypesDetails.object_list.map((value, key) =>  value.type === 'assign-leads' && (
                                                <li key={'tele'+key}>
                                                    <NavLink  to={{ pathname: `${path}/follow/${value.slug}`, state: value }}  onClick={addParentActive} >
                                                    {value.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    )}

                                    
                                    {agentStatus?.lead_type === 'dial_out'  && (
                                    <li className="has-subMenu">
                                        <Link to="#"  className={fullPath[3] === 'follow_demo_register' ? 'active parent' : 'parent'} > 
                                            <FeatherIcon icon="aperture" /> Dial Out
                                        </Link>
                                        <ul className="subMenu">
                                            <li className="has-subMenu-left">
                                                <Link to="#" className="parent">
                                                    Complaint Register  
                                                </Link>
                                                <ul className="subMenu">
                                                    {getRequestTypesDetails && getRequestTypesDetails.object_list && getRequestTypesDetails.object_list.length > 0 && getRequestTypesDetails.object_list.map((value, key) =>  value.type === 'dail-out' && (
                                                        <li key={'dial-comp'+key}>
                                                            <NavLink  to={{ pathname: `${path}/follow/${value.slug}`, state: value }}  onClick={addParentActive} >
                                                            {value.title}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>

                                            <li className="has-subMenu-left">
                                                <Link to="#" className="parent">
                                                    Happy
                                                </Link>
                                                <ul className="subMenu">
                                                    <li>
                                                        <NavLink to={`${path}/demo_happy`} onClick={addParentActive}>
                                                            Demo Happy
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/demo_happy_sap`} onClick={addParentActive}>
                                                            Demo Happy - SAP
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/complaint_happy`} onClick={addParentActive}>
                                                            Complaint Happy
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/furniture_complaint_happy`} onClick={addParentActive}>
                                                            Furniture Complaint Happy
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/legal_case_happy`} onClick={addParentActive}>
                                                            Legal Case Happy
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/stock_defective_happy`} onClick={addParentActive}>
                                                            Stock Set Defective
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/defective_unit_delivered_happy`} onClick={addParentActive}>
                                                            Defective Unit Delivered
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/escalation_happy`} onClick={addParentActive}>
                                                            Escalation
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="has-subMenu-left">
                                                <Link to="#" className="parent">
                                                    Scheduled Log
                                                </Link>
                                                <ul className="subMenu">
                                                    <li>
                                                        <NavLink to={`${path}/schedule_log/complaint-register`} onClick={addParentActive}>
                                                            Complaint 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/schedule_log/demo-register`} onClick={addParentActive} >
                                                            Demo
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/sap_schedule_log`} onClick={addParentActive}>
                                                            Demo - SAP
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/schedule_log/furniture-complaint-register`} onClick={addParentActive}>
                                                            Furniture Complaint 
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`${path}/schedule_log/defective-unit-delivered`} onClick={addParentActive}>
                                                            Defective Unit Delivered
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/stock_defective_schedule_log`} onClick={addParentActive}>
                                                            Stock Set Defective
                                                        </NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink to={`${path}/escalation_schedule_log`} onClick={addParentActive}>
                                                            Escalation
                                                        </NavLink>
                                                    </li> */}
                                                </ul>
                                            </li>

                                            <li className="has-subMenu-left">
                                                <Link to="#" className="parent">
                                                    Priority Log
                                                </Link>
                                                <ul className="subMenu">
                                                    <li>
                                                        <NavLink to={`${path}/priority_log/complaint-register`} onClick={addParentActive}>
                                                            Complaint 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/priority_log/demo-register`} onClick={addParentActive} >
                                                            Demo
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/sap_priority_log`} onClick={addParentActive}>
                                                            Demo - SAP
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/priority_log/furniture_complaint_register`} onClick={addParentActive}>
                                                            Furniture Complaint 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/priority_log/defective-unit-delivered`} onClick={addParentActive}>
                                                            Defective Unit Delivered
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`${path}/stock_defective_priority_log`} onClick={addParentActive}>
                                                            Stock Set Defective
                                                        </NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink to={`${path}/escalation_priority_log`} onClick={addParentActive}>
                                                            Escalation
                                                        </NavLink>
                                                    </li> */}
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    )}
                                    
                                    <li>
                                        <Link to={`${path}/call_record`} className={fullPath[3] === 'call_record' ? 'active' :null}>
                                            <FeatherIcon icon="phone-call" />  Call Record
                                        </Link>
                                    </li>
                                </>
                            )}
                            <li>
                                <Link to={`${path}/report`} className={fullPath[3] === 'report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Report
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/available_report`} className={fullPath[3] === 'available_report' ? 'active' :null}>
                                    <FeatherIcon icon="file-text" />  Ava. Report
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'maintenance_employee' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null}>
                                    <FeatherIcon icon="home" />Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/mt_list`} className={fullPath[3] === 'mt_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Open 
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/mt_follow`} className={fullPath[3] === 'mt_follow' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Follow Up 
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                


                { fullPath[2] === 'vendor' && fullPath[3] === 'extended' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to={`${path}/complaint`} className={fullPath[3] === 'store_list' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Complaint List
                                </Link>
                            </li>
                        </ul>
                    </>
                )}

                { fullPath[2] === 'vendor' && fullPath[3] === 'outofwarranty' &&(
                    <>
                        <ul>
                            <li>
                                <Link to={`${path}/dashboard`} className={fullPath[3] === 'dashboard' ? 'active' :null} >
                                    <FeatherIcon icon="home" /> Dashboard
                                </Link>
                            </li>
                            <li>
                            
                                <Link to={`${path}/complaint`} className={fullPath[3] === 'complaint' ? 'active' :null}>
                                    <FeatherIcon icon="list" /> Complaint List
                                </Link>
                            </li>
                        </ul>
                    </>
                )}



                { fullPath[2] === 'profile' &&(
                    <>
                        <ul>
                            {/* <li>
                                <Link to={`${path}`} className={fullPath.length === '3' && fullPath[2] === 'profile' ? 'active' :null}>
                                    <FeatherIcon icon="user" /> My Profile
                                </Link>
                            </li> */}
                            <li>
                                <Link to={`${path}/change_password`} className={ fullPath[2] === 'profile' && fullPath[3] === 'change_password' ? 'active' :null}>
                                    <FeatherIcon icon="lock" /> Change Password
                                </Link>
                            </li>
                            {/* <li>
                                <Link to={`${path}/setting`} className={fullPath[3] === 'setting' ? 'active' :null} >
                                    <FeatherIcon icon="settings" />Account Setting
                                </Link>
                            </li> */}
                            
                            {/* <li>
                                <Link to={`${path}/notification`} className={fullPath[3] === 'notification' ? 'active' :null}>
                                    <FeatherIcon icon="bell" />  Notification
                                </Link>
                            </li> */}
                        </ul>
                    </>
                )}

            </div>
        </TopMenuStyle>
    );
}

export default TopMenu;
