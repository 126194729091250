import React,{ useState, useEffect }  from 'react';
import { Row, Col, Form, Input,  Select, Drawer, Button, notification, Checkbox } from 'antd';
import { useSelector,useDispatch } from 'react-redux';
import Heading from '../heading/heading';

import salesImage from '../../static/img/login-icon/admin/sales.svg';
import customerImage from '../../static/img/login-icon/admin/customer.svg';
import employeeImage from '../../static/img/login-icon/admin/employee.svg';
import storeImage from '../../static/img/login-icon/admin/store.svg';
import inventoryImage from '../../static/img/login-icon/admin/inventory.svg';
// import feedbackImage from '../../static/img/login-icon/admin/feedback.svg';
import missingImage from '../../static/img/login-icon/admin/missed-order.svg';
import outofwarrantyImage from '../../static/img/login-icon/admin/outofwarranty.svg';
import warrantyImage from '../../static/img/login-icon/admin/warranty.svg';
import customerQueriesImage from '../../static/img/login-icon/admin/customer-queries.svg';
import escalationImage from '../../static/img/login-icon/admin/escalation.svg';
import customerCareImage from '../../static/img/login-icon/admin/customercare.svg';
import maintenanceImage from '../../static/img/login-icon/admin/maintenance.svg';
import dataImage from '../../static/img/login-icon/admin/data.svg';
import qualityImage from '../../static/img/login-icon/admin/quality.svg';
import { editCustomEmployeeDetails, vendorPincodeList } from '../../redux/employee/actionCreator';

const { Option } = Select;

const EditCustomEmployee = ({editCustomEmployee,setEditCustomEmployee,setPassData,...props}) => {

    const [form] = Form.useForm();
    const employeeDetails = props.employeeData;

    useEffect(() => {
        if(editCustomEmployee){
            form.resetFields();
        }
    },[form,editCustomEmployee])

    const dispatch = useDispatch();

    const onClose = () => {
        setEditCustomEmployee(false);
    }

    const [loading, setLoading] = useState(false); 

    const handleSubmit = (value) => {
        try {
            
            const modifiedValue = { ...value,permission:checkedList, employee_id:employeeDetails?.employee_id,};
            
            dispatch(editCustomEmployeeDetails(modifiedValue));
            form.resetFields();
            setLoading(true); 
            setEditCustomEmployee(false);
            notification.success({
                message: 'Submitted Successfully',
            });
        } catch (error) {
            notification.error({
                message: error,
            });
            
        } finally {
            // setLoading(false); // Reset loading state after form submission is complete
        }
    }


    const [list,setList] = useState([]);
    const [showPermissionControl,setShowPermissionControl] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [showVendorPincode,setShowVendorPincode] = useState(false);

    const onHandleUserType = (value) => {
        setShowVendorPincode(false);
        setShowPermissionControl(true);
        if(value === 'admin'){
            setList([
                {title:'Sales',value:'sales', image_url:salesImage},
                {title:'Customer',value:'customer', image_url:customerImage}, 
                {title:'Employee',value:'employee', image_url:employeeImage},
                {title:'Store',value:'store', image_url:storeImage},
                {title:'Inventory',value:'inventory', image_url:inventoryImage},
                {title:'Walkout Data',value:'walkout', image_url:missingImage},
                {title:'Data Management',value:'data', image_url:dataImage},
                {title:'Purchase Queries',value:'purchase', image_url:customerQueriesImage},
                {title:'Call Monitoring',value:'customercare', image_url:customerCareImage},
                {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
                {title:'Quality & Training',value:'quality', image_url:qualityImage},
                {title:'Extended Warranty',value:'warranty', image_url:warrantyImage},
                {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
                {title:'Escalation',value:'escalation', image_url:escalationImage},
            ]);
        }

        if(value === 'vendor'){
            setList([
                {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
                {title:'Extended Warranty',value:'extended-warranty', image_url:warrantyImage},
            ]);
            setShowVendorPincode(true);
        }
            

        form.setFieldsValue({
            user_type: value,
        });
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
    }

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ?  list.map(li => li.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < list.length);
        setCheckAll(checkedValues.length === list.length);
    };

    useEffect(() => {

        setShowVendorPincode(false);
        setShowPermissionControl(true);


        if(employeeDetails?.user_type === 'admin'){
            setList([
                {title:'Sales',value:'sales', image_url:salesImage},
                {title:'Customer',value:'customer', image_url:customerImage}, 
                {title:'Employee',value:'employee', image_url:employeeImage},
                {title:'Store',value:'store', image_url:storeImage},
                {title:'Inventory',value:'inventory', image_url:inventoryImage},
                {title:'Walkout Data',value:'walkout', image_url:missingImage},
                {title:'Data Management',value:'data', image_url:dataImage},
                {title:'Purchase Queries',value:'purchase', image_url:customerQueriesImage},
                {title:'Call Monitoring',value:'customercare', image_url:customerCareImage},
                {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
                {title:'Quality & Training',value:'quality', image_url:qualityImage},
                {title:'Extended Warranty',value:'warranty', image_url:warrantyImage},
                {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
                {title:'Escalation',value:'escalation', image_url:escalationImage},
            ]);
        }   

        if(employeeDetails?.user_type === 'vendor'){
            setShowVendorPincode(true);
            setList([
                {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
                {title:'Extended Warranty',value:'extended-warranty', image_url:warrantyImage},
            ]);
            dispatch(vendorPincodeList());
        }

        setCheckedList(employeeDetails?.permission);
        setIndeterminate(false);
        setCheckAll(false);
    },[employeeDetails])


    const pincodeList = useSelector((state) => state.vendorPincodeList?.data);
    const initialValues = {
        employee_id:employeeDetails?.employee_id,
        first_name: employeeDetails?.name?.split(" ") ? employeeDetails?.name?.split(" ")[0]: '',
        last_name: employeeDetails?.name?.split(" ") ? employeeDetails?.name?.split(" ")[1]: '',
        mobile: employeeDetails?.mobile,
        email: employeeDetails?.email,
        user_type: employeeDetails?.user_type,
        pincode_list:employeeDetails?.pincode_list,
    };
  
    return (
        <>
            <Drawer title="Edit Custom Employee" width={640} onClose={onClose} open={editCustomEmployee} bodyStyle={{ paddingBottom: 80, }}>
                <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="first_name" label="First Name" rules={[ { required: true, message: 'Please Enter First Name', },]} >
                                <Input placeholder="Please Enter First  Name"  />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" label="Last Name" rules={[ { required: false, message: 'Please Enter Last Name', },]} >
                                <Input placeholder="Please Enter Last Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="mobile" label="Mobile" rules={[
                                { type: 'number', message: 'Please enter Mobile Number!',
                                    asyncValidator: (rules, value) => {
                                        return new Promise((resolve, reject) => {
                                            if (/^[0-9]+$/.test(value)) {
                                                resolve();
                                            } else {
                                                reject();
                                            }
                                        });
                                    },
                                }, 
                                { required: true, message: 'Please enter Mobile', }, ]}>
                                <Input placeholder="Please enter Mobile" maxLength={10}   />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="email" label="Email" rules={[ { required: false, message: 'Please Enter Employee Email', },]} >
                                <Input placeholder="Please Enter Employee Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Heading as="h4"> Permission </Heading>
                    <Row gutter={24}>
                        <Col span={12} xs={24} lg={12} >
                            <Form.Item name="user_type" label="Login Type" rules={[ { required: true, message: 'Please Choose any one Login Type' }]} >
                                <Select placeholder="Please choose the Login Type" onChange={onHandleUserType} allowClear>
                                    <Option value="admin">Admin</Option>
                                    <Option value="vendor">Vendor</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        { showVendorPincode && (
                            <Col span={12} xs={24} lg={12} >
                                <Form.Item name="pincode_list" label="Pincode" rules={[ { required: false, message: 'Please Choose any one Pincode', }, ]} >  
                                    <Select 
                                        mode="multiple"
                                        placeholder="Please choose any One Pincode" 
                                        options={pincodeList && pincodeList.map(data => ({ label: data.code, value: data.code }))}
                                        optionFilterProp="children"
                                        showSearch
                                        showArrow={true}
                                        notFoundContent={null}
                                        allowClear
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        
                    </Row>


                    { showPermissionControl && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Heading as="h4"> 
                                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} >
                                            Check All Portal
                                        </Checkbox>
                                    </Heading>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange}>
                                        <Row>
                                            {list && list.map((lis,key) => (
                                                <Col xxl={6} xl={6} lg={6} xs={12} key={'col'+key}>
                                                    <Checkbox key={'check'+key} value={lis.value}>
                                                        <div className="text-center">
                                                            <div className="text-center">
                                                                <img src={lis.image_url} width='100' alt='' />
                                                            </div>
                                                            {lis.title}
                                                        </div>
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="sDash_form-action mt-30">
                                <Button type="danger" size="large"  onClick={onClose}>Close</Button>
                                <Button htmlType="submit" type="primary" size="large" disabled={loading} style={{float:'right'}}>
                                    {loading ? 'Please Wait...' : 'Submit'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Drawer> 
        </>
    )
}

export default EditCustomEmployee