import React, { useEffect} from 'react'
import { Row, Col, Form, Input, Drawer, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { GeneralFormWrapper } from '../../container/styled';
import { updateEmployeePassword,resetEmployeePassword } from '../../redux/employee/actionCreator';
import { useDispatch, useSelector } from 'react-redux'; 

const ChangePassword = ({showPassword,setShowPassword,setPassData,...props}) => {

    const [form] = useForm();
    const employeeDetails = props.employeeData;
    const onClose = () => {
        setShowPassword(false);
        setPassData({
            employeeData : '',
        })
    }

    const validatePassword = (_, value) => {
        if (value && value.length < 8) {
            return Promise.reject(new Error('Password must be at least 8 characters'));
        }
        return Promise.resolve();
    };

    const dispatch = useDispatch();

    const handleSubmit = (value) => {
        const modifiedValue = { ...value,employee_id:employeeDetails.id};
        dispatch(updateEmployeePassword(modifiedValue));
    }

    const employeePassword = useSelector((state) => state.employeePassword);
    
    useEffect(() => {
        if(employeePassword?.data === 'submitted' && employeePassword?.loading === false){
            setShowPassword(false);
            Modal.info({
                title: 'Password has been updated successfully.',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    resetEmployeePassword();
                    form.resetFields();
                  // Add your custom action here
                },
            });
        }

        if(employeePassword?.error  && employeePassword?.loading === false){
            setShowPassword(false);
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    resetEmployeePassword()
                    form.resetFields();
                  // Add your custom action here
                },
            });
        }

    },[employeePassword])


    return (
        <>
            <Drawer title="Reset Password" onClose={onClose} open={showPassword} bodyStyle={{ paddingBottom: 80, }} placement='left'>
                <GeneralFormWrapper>
                    <Form layout="vertical" form={form} onFinish={handleSubmit} >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="password" label="New Password"   rules={[ { required: true, message: 'Please Enter New Password' },{ validator: validatePassword }]} >
                                    <Input.Password placeholder="Please Enter New Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="sDash_form-action">
                            <button type="submit" className="ant-btn ant-btn-primary ant-btn-lg">
                                Submit
                            </button>
                        </div>
                    </Form>
                </GeneralFormWrapper>
            </Drawer>
        </>
    )
}

export default ChangePassword