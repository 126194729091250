import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Drawer, Divider, Checkbox } from 'antd';
import Heading from '../heading/heading';
import { GeneralFormWrapper } from '../../container/styled';
import { updateEmployeeUserType } from '../../redux/employee/actionCreator';
import { CustomerDetailsWrapper} from '../../container/agent/style';
import { useDispatch, useSelector } from 'react-redux'; 
import salesImage from '../../static/img/login-icon/admin/sales.svg';
import customerImage from '../../static/img/login-icon/admin/customer.svg';
import employeeImage from '../../static/img/login-icon/admin/employee.svg';
import storeImage from '../../static/img/login-icon/admin/store.svg';
import inventoryImage from '../../static/img/login-icon/admin/inventory.svg';
import feedbackImage from '../../static/img/login-icon/admin/feedback.svg';
import missingImage from '../../static/img/login-icon/admin/missed-order.svg';
import warrantyImage from '../../static/img/login-icon/admin/warranty.svg';
import outofwarrantyImage from '../../static/img/login-icon/admin/outofwarranty.svg';
import escalationImage from '../../static/img/login-icon/admin/escalation.svg';
import customerQueriesImage from '../../static/img/login-icon/admin/customer-queries.svg';
import stockImage from '../../static/img/login-icon/admin/stock-defective.svg';
import customerCareImage from '../../static/img/login-icon/admin/customercare.svg';
import maintenanceImage from '../../static/img/login-icon/admin/maintenance.svg';
import dataImage from '../../static/img/login-icon/admin/data.svg';
import qualityImage from '../../static/img/login-icon/admin/quality.svg';
import reviewImage from '../../static/img/login-icon/admin/review.svg';

import { getMaintenanceDepartment } from '../../redux/maintenance/actionCreator';

const { Option } = Select;

const ChangePermission = ({showPermission,setShowPermission,setPassData,...props}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const employeeDetails = props.employeeData;

    const onClose = () => {
        setShowPermission(false);
        setPassData({
            employeeData : ''
        })
        form.resetFields();
        setShowPermissionControl(false);
        resetPermissionList();
    }
    
    useEffect(() => {
        if(showPermission){
            form.resetFields();
            setShowPermissionControl(false);
            resetPermissionList();
        }   
        //
    },[form,showPermission])

    useEffect(() => {
        dispatch(getMaintenanceDepartment());
    },[dispatch])

    const maintenanceDepartmentData = useSelector((state) => state.maintenanceDepartment.data);

    const [list,setList] = useState([]);

    const [showPermissionControl,setShowPermissionControl] = useState(false);
   
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [showMaintenanceDepartment,setShowMaintenanceDepartment] = useState(false);

    const checkPermissionList = (value) => {
        if(value === 'admin'){
            setList([
                {title:'Sales',value:'sales', image_url:salesImage},
                {title:'Customer',value:'customer', image_url:customerImage}, 
                {title:'Employee',value:'employee', image_url:employeeImage},
                {title:'Store',value:'store', image_url:storeImage},
                {title:'Inventory',value:'inventory', image_url:inventoryImage},
                {title:'Walkout Data',value:'walkout', image_url:missingImage},
                {title:'Data Management',value:'data', image_url:dataImage},
                {title:'Purchase Queries',value:'purchase', image_url:customerQueriesImage},
                {title:'Call Monitoring',value:'customercare', image_url:customerCareImage},
                {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
                {title:'Quality & Training',value:'quality', image_url:qualityImage},
                {title:'Extended Warranty',value:'warranty', image_url:warrantyImage},
                {title:'Out of Warranty',value:'out-of-warranty', image_url:outofwarrantyImage},
                {title:'Escalation',value:'escalation', image_url:escalationImage},
                {title:'Review',value:'review', image_url:reviewImage},
                {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
            ]);
        }
        
        if(value === 'agent'){
            setList([{title:'Customer Care',value:'agent', image_url:customerCareImage}]);
        }
            
        if(value === 'employee'){
            setList([
                {title:'Inventory',value:'inventory', image_url:inventoryImage},
                {title:'Walkout Data',value:'walkout', image_url:missingImage},
                {title:'Walkin Complaints',value:'walkin', image_url:warrantyImage},
                {title:'Purchase Enquiry',value:'purchase', image_url:customerQueriesImage},
                {title:'Customer FeedBack',value:'feedback', image_url:feedbackImage},
                {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
                {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
                {title:'Review',value:'review', image_url:reviewImage},
            ]);
        }
        
        if(value === 'maintenance'){
            setList([
                {title:'Maintenance',value:'maintenance', image_url:maintenanceImage},
            ]);
            setShowMaintenanceDepartment(true);
        }
        
        if(value === 'stock'){
            setList([
                {title:'Stock Defective',value:'stock-defective', image_url:stockImage},
            ]);
        }
    }

    const resetPermissionList = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
    }

    const onHandleUserType = (value) => {
        setShowMaintenanceDepartment(false);
        setShowPermissionControl(true);
        checkPermissionList(value);
        form.setFieldsValue({
            user_type: value,
        });
        resetPermissionList();
    }

    const autoHandleUserType = (value) =>{
        setShowMaintenanceDepartment(false);
        setShowPermissionControl(true);
        checkPermissionList(value);
    }

    useEffect(() => {
        if(employeeDetails?.user_type && employeeDetails.user_type !== ''){
            autoHandleUserType(employeeDetails?.user_type)
            setCheckedList(employeeDetails?.permission);
            setIndeterminate(!!employeeDetails?.permission.length && employeeDetails?.permission.length < list.length);
            setCheckAll(employeeDetails?.permission.length === list.length);
        }  
        //eslint-disable-next-line
    },[employeeDetails])
   
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ?  list.map(li => li.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < list.length);
        setCheckAll(checkedValues.length === list.length);
    };

    const handleSubmit = (value) => {
        const modifiedValue = { ...value,permission:checkedList, employee_id:employeeDetails.id};
        dispatch(updateEmployeeUserType(modifiedValue));
        form.resetFields();
        setShowPermission(false);
    }
    
    useEffect(() => {
        if(employeeDetails){
            const initialValues = {
                user_type:employeeDetails?.user_type,
                department_id: employeeDetails?.department?.id,
            }
            form.setFieldsValue(initialValues);
        }   
    },[employeeDetails,form])

    return (
        <>
            <Drawer title="Role Permission" height={'90%'} onClose={onClose} open={showPermission} bodyStyle={{ paddingBottom: 80, }} placement='top'>
                <GeneralFormWrapper>
                    <CustomerDetailsWrapper>
                        <Row gutter={25}>
                            <Col xxl={24} xl={24} xs={24}>
                                <div className="about-project">
                                    <Row gutter={25}>
                                        {employeeDetails?.employee_id && (
                                        <Col xxl={4} xl={6}  lg={6} xs={24}>
                                            <span> Employee ID </span>
                                            <p>{employeeDetails?.employee_id ? employeeDetails.employee_id : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.name && (
                                        <Col xxl={4} xl={6}  lg={6} xs={24}>
                                            <span> Employee Name</span>
                                            <p>{employeeDetails?.name ? employeeDetails.name : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.mobile && (
                                        <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                            <span> Mobile Number </span>
                                            <p className="color-primary">{employeeDetails?.mobile ? employeeDetails.mobile : '-' }</p>
                                        </Col>
                                        )}
                                        {employeeDetails?.email && (
                                        <Col xxl={4}  xl={6}  lg={6}  xs={24}>
                                            <span>Email</span>
                                            <p>{employeeDetails?.email  ? employeeDetails.email : '-' }</p>
                                        </Col>
                                        )}

                                        {employeeDetails?.branch?.name && (
                                        <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                            <span>Branch</span>
                                            <p >{employeeDetails?.branch?.name ? employeeDetails.branch?.name : '-' }</p>
                                        </Col>
                                        )}
                                        
                                        {employeeDetails?.employee_category?.name && (
                                            <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                                <span>Category</span>
                                                <p>{employeeDetails?.employee_category?.name ? employeeDetails.employee_category.name : '-' }</p>
                                            </Col>
                                        )}
                                        
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CustomerDetailsWrapper>
                    <Divider dashed />
                    <Heading as="h4"> Permission </Heading>
                    <Divider dashed />
                    <Form  form={form} onFinish={handleSubmit}>
                        <Row gutter={24}>
                            <Col span={7} xs={24} lg={6} >
                                <Form.Item name="user_type" label="Login Type" rules={[ { required: true, message: 'Please Choose any one Login Type' }]} >
                                    <Select placeholder="Please choose the Login Type" onChange={onHandleUserType} allowClear>
                                        <Option value="admin">Admin</Option>
                                        <Option value="agent">Agent</Option>
                                        <Option value="employee">Store Employee</Option>
                                        <Option value="maintenance">Maintenance Employee</Option>
                                        <Option value="stock">Stock Employee</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            { showMaintenanceDepartment && (
                            <Col span={5} xs={24} lg={6} >
                                <Form.Item name="department_id" label="Department" rules={[ { required: true, message: 'Please Choose any one Department' }]} >
                                <Select 
                                    placeholder="Please choose the Department" 
                                    options={maintenanceDepartmentData && maintenanceDepartmentData.map(data => ({ label: data.name, value: data.id }))}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    showSearch
                                    showArrow={true}
                                    notFoundContent={null}
                                    allowClear
                                >
                                </Select>
                                </Form.Item>
                            </Col>
                            )}

                        </Row>
                        { showPermissionControl && (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Heading as="h4"> 
                                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} >
                                                Check All Portal
                                            </Checkbox>
                                        </Heading>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange}>
                                            <Row>
                                                {list && list.map((lis,key) => (
                                                    <Col xxl={3} xl={3} lg={3} xs={12} key={'column'+key}>
                                                        <Checkbox key={'check-'+key} value={lis.value}>
                                                            <div className="text-center">
                                                                <div className="text-center">
                                                                    <img src={lis.image_url} width='100' alt='' />
                                                                </div>
                                                                {lis.title}
                                                            </div>
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <div className="sDash_form-action">
                            <button type="submit" className="ant-btn ant-btn-primary ant-btn-lg mt-30">
                                Submit
                            </button>
                        </div>
                    </Form>
                </GeneralFormWrapper>
            </Drawer>
        </>
    )
}

export default ChangePermission