const actions = {
    
    DEFECTIVE_LIST_DATA_BEGIN : 'DEFECTIVE_LIST_DATA_BEGIN',
    DEFECTIVE_LIST_DATA_SUCCESS: 'DEFECTIVE_LIST_DATA_SUCCESS',
    DEFECTIVE_LIST_DATA_ERR : 'DEFECTIVE_LIST_DATA_ERR',

    ASSIGN_AGENT_DEFECTIVE_DATA_BEGIN : 'ASSIGN_AGENT_DEFECTIVE_DATA_BEGIN',
    ASSIGN_AGENT_DEFECTIVE_DATA_SUCCESS: 'ASSIGN_AGENT_DEFECTIVE_DATA_SUCCESS',
    ASSIGN_AGENT_DEFECTIVE_DATA_ERR : 'ASSIGN_AGENT_DEFECTIVE_DATA_ERR',
    ASSIGN_AGENT_DEFECTIVE_DATA_RESET : 'ASSIGN_AGENT_DEFECTIVE_DATA_RESET',

    VIEW_DEFECTIVE_DATA_BEGIN : 'VIEW_DEFECTIVE_DATA_BEGIN',
    VIEW_DEFECTIVE_DATA_SUCCESS: 'VIEW_DEFECTIVE_DATA_SUCCESS',
    VIEW_DEFECTIVE_DATA_ERR : 'VIEW_DEFECTIVE_DATA_ERR',

    DEFECTIVE_HISTORY_DATA_BEGIN : 'DEFECTIVE_HISTORY_DATA_BEGIN',
    DEFECTIVE_HISTORY_DATA_SUCCESS: 'DEFECTIVE_HISTORY_DATA_SUCCESS',
    DEFECTIVE_HISTORY_DATA_ERR : 'DEFECTIVE_HISTORY_DATA_ERR',

    DEFECTIVE_EXCEL_HEADER_DATA_BEGIN:'DEFECTIVE_EXCEL_HEADER_DATA_BEGIN',
    DEFECTIVE_EXCEL_HEADER_DATA_SUCCESS: 'DEFECTIVE_EXCEL_HEADER_DATA_SUCCESS',
    DEFECTIVE_EXCEL_HEADER_DATA_ERR: 'DEFECTIVE_EXCEL_HEADER_DATA_ERR',

    DEFECTIVE_SUBMIT_FILE_DATA_BEGIN: 'DEFECTIVE_SUBMIT_FILE_DATA_BEGIN',
    DEFECTIVE_SUBMIT_FILE_DATA_SUCCESS: 'DEFECTIVE_SUBMIT_FILE_DATA_SUCCESS',
    DEFECTIVE_SUBMIT_FILE_DATA_ERR: 'DEFECTIVE_SUBMIT_FILE_DATA_ERR',

    DEFECTIVE_DATE_BEGIN : 'DEFECTIVE_DATE_BEGIN',
    DEFECTIVE_DATE_SUCCESS: 'DEFECTIVE_DATE_SUCCESS',
    DEFECTIVE_DATE_ERR : 'DEFECTIVE_DATE_ERR',

    DEFECTIVE_SAVE_DATA_BEGIN : 'DEFECTIVE_SAVE_DATA_BEGIN',
    DEFECTIVE_SAVE_DATA_SUCCESS: 'DEFECTIVE_SAVE_DATA_SUCCESS',
    DEFECTIVE_SAVE_DATA_ERR : 'DEFECTIVE_SAVE_DATA_ERR',

    DEFECTIVE_DOWNLOAD_DATA_BEGIN : 'DEFECTIVE_DOWNLOAD_DATA_BEGIN',
    DEFECTIVE_DOWNLOAD_DATA_SUCCESS: 'DEFECTIVE_DOWNLOAD_DATA_SUCCESS',
    DEFECTIVE_DOWNLOAD_DATA_ERR : 'DEFECTIVE_DOWNLOAD_DATA_ERR',

    DEFECTIVE_EXCEL_DATA_BEGIN : 'DEFECTIVE_EXCEL_DATA_BEGIN',
    DEFECTIVE_EXCEL_DATA_SUCCESS: 'DEFECTIVE_EXCEL_DATA_SUCCESS',
    DEFECTIVE_EXCEL_DATA_ERR : 'DEFECTIVE_EXCEL_DATA_ERR',

    defectiveListDataBegin: () => {
        return {
            type: actions.DEFECTIVE_LIST_DATA_BEGIN,
        };
    },

    defectiveListDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_LIST_DATA_SUCCESS,
            data,
        };
    },

    defectiveListDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_LIST_DATA_ERR,
            err,
        };
    },

    assignAgentDefectiveDataBegin: () => {
        return {
            type: actions.ASSIGN_AGENT_DEFECTIVE_DATA_BEGIN,
        };
    },

    assignAgentDefectiveDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_AGENT_DEFECTIVE_DATA_SUCCESS,
            data,
        };
    },

    assignAgentDefectiveDataErr: (err) => {
        return {
            type: actions.ASSIGN_AGENT_DEFECTIVE_DATA_ERR,
            err,
        };
    },
    
    assignAgentDefectiveDataReset: (err) => {
        return {
            type: actions.ASSIGN_AGENT_DEFECTIVE_DATA_RESET,
            err,
        };
    },

    viewDefectiveDataBegin: () => {
        return {
            type: actions.VIEW_DEFECTIVE_DATA_BEGIN,
        };
    },

    viewDefectiveDataSuccess: (data) => {
        return {
            type: actions.VIEW_DEFECTIVE_DATA_SUCCESS,
            data,
        };
    },

    viewDefectiveDataErr: (err) => {
        return {
            type: actions.VIEW_DEFECTIVE_DATA_ERR,
            err,
        };
    },

    defectiveHistoryDataBegin: () => {
        return {
            type: actions.DEFECTIVE_HISTORY_DATA_BEGIN,
        };
    },

    defectiveHistoryDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    defectiveHistoryDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_HISTORY_DATA_ERR,
            err,
        };
    },

    excelStockDefectiveHeaderDataBegin: () => {
        return {
            type: actions.DEFECTIVE_EXCEL_HEADER_DATA_BEGIN,
        };
    },

    excelStockDefectiveHeaderDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_EXCEL_HEADER_DATA_SUCCESS,
            data,
        };
    },

    excelStockDefectiveHeaderDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_EXCEL_HEADER_DATA_ERR,
            err,
        };
    },

    

    submitStockDefectiveDemoDataBegin: () => {
        return {
            type: actions.DEFECTIVE_SUBMIT_FILE_DATA_BEGIN,
        };
    },

    submitStockDefectiveDemoDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_SUBMIT_FILE_DATA_SUCCESS,
            data,
        };
    },

    submitStockDefectiveDemoDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_SUBMIT_FILE_DATA_ERR,
            err,
        };
    },

    stockDefectiveExcelDataBegin: () => {
        return {
            type: actions.DEFECTIVE_EXCEL_DATA_BEGIN,
        };
    },

    stockDefectiveExcelDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_EXCEL_DATA_SUCCESS,
            data,
        };
    },

    stockDefectiveExcelDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_EXCEL_DATA_ERR,
            err,
        };
    },
    
    stockDefectiveReportDataBegin: () => {
        return {
            type: actions.DEFECTIVE_DATE_BEGIN,
        };
    },

    stockDefectiveReportDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_DATE_SUCCESS,
            data,
        };
    },

    stockDefectiveReportDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_DATE_ERR,
            err,
        };
    },

    stockDefectiveReportSaveDataBegin: () => {
        return {
            type: actions.DEFECTIVE_SAVE_DATA_BEGIN,
        };
    },

    stockDefectiveReportSaveDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_SAVE_DATA_SUCCESS,
            data,
        };
    },

    stockDefectiveReportSaveDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_SAVE_DATA_ERR,
            err,
        };
    },

    stockDefectiveReportDownloadDataBegin: () => {
        return {
            type: actions.DEFECTIVE_DOWNLOAD_DATA_BEGIN,
        };
    },

    stockDefectiveReportDownloadDataSuccess: (data) => {
        return {
            type: actions.DEFECTIVE_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    stockDefectiveReportDownloadDataErr: (err) => {
        return {
            type: actions.DEFECTIVE_DOWNLOAD_DATA_ERR,
            err,
        };
    },
    
};

export default actions;