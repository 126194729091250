import React, { useEffect} from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestTypes } from '../redux/agent/actionCreator';


const { SubMenu } = Menu;
function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const fullPath = pathName.split('/');
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
    const [openKeys, setOpenKeys] = React.useState(
        !topMenu ? [`${mainPathSplit.length > 3 ? mainPathSplit[1] : 'dashboard'}`] : [],
    );

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const onClick = (item) => {
        if (item.keyPath.length === 1) setOpenKeys([]);
    };

    const agentStatus = useSelector((state) => state.agentStatus.data);
   
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch(getRequestTypes())
    },[dispatch])

    const getRequestTypesDetails = useSelector((state) => state.getRequestTypes.data);
    

    return (
        <>
            <p className="sidebar-nav-title">{ fullPath[2] } MENU </p>
            <Menu onOpenChange={onOpenChange} onClick={onClick} mode={'inline'} theme={darkMode && 'dark'}
                // // eslint-disable-next-line no-nested-ternary
                defaultSelectedKeys={
                    !topMenu
                    ? [
                        `${
                            mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                        }`,
                        ]
                    : []
                }
                defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                overflowedIndicator={<FeatherIcon icon="more-vertical" />}
                openKeys={openKeys}
            >
               
                { fullPath[2] === 'sales' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/sales_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="sales_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/sales_list`}>
                                Sales List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'customer' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/customer_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="customer_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/customer_list`}>
                                Customers
                            </NavLink>
                        </Menu.Item>

                        {/* <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/customer_group`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="customer_group"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/customer_group`}>
                                Customers Group
                            </NavLink>
                        </Menu.Item> */}

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'employee' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        <SubMenu key="profile" icon={!topMenu && <FeatherIcon icon="users" />} title="Employee">
                            <Menu.Item key="employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/employee_list`}>
                                    Employee List
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="isd_employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/isd_employee_list`}>
                                    ISD Employee List
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="custom_employee_list">
                                <NavLink onClick={toggleCollapsed} to={`${path}/custom_employee_list`}>
                                    Custom Employee
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>
                        {/* <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/employee_control`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="employee_control"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/employee_control`}>
                                Employee Control
                            </NavLink>
                        </Menu.Item> */}
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="flag" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="flag" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
        
                    </>
                )}

                { fullPath[2] === 'store' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/store_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="store_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/store_list`}>
                                Store List
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'inventory' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/inventory_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="inventory_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/inventory_list`}>
                                Inventory List
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/inventory_request`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="inventory_request"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/inventory_request`}>
                                Inventory Request
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/search_product`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="search_product"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/search_product`}>
                                Search Product
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'walkout' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/category`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="category"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/category`}>
                                Categories
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/list`}>
                                Walkout Customer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'datamanagement' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="register_data_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Register Data List" class="parent">
                            { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0 && (
                                <>
                                    {getRequestTypesDetails.object_list.map((value,key) => (
                                        <>
                                            {(value.menu_type === 'register' && value.slug === 'demo-register') && (
                                                <SubMenu key="demo_register" title="Demo Register" class="unber_sub_menu"> 
                                                    <Menu.Item key={'sap'}>
                                                        <NavLink onClick={toggleCollapsed} to={{ pathname:`${path}/demo_register/sap`, state:value}}  >
                                                            Demo Register SAP
                                                        </NavLink>
                                                    </Menu.Item>
                                                    <Menu.Item key={value.slug}>
                                                        <NavLink onClick={toggleCollapsed} to={{ pathname:`${path}/follow/${value.slug}`, state:value}}  >
                                                            Demo Register Agent
                                                        </NavLink>
                                                    </Menu.Item>
                                                </SubMenu>
                                            )}
                                            {(value.menu_type === 'register' && value.slug !== 'demo-register') && (
                                                <Menu.Item key={value.slug}>
                                                    <NavLink onClick={toggleCollapsed} to={{ pathname:`${path}/follow/${value.slug}`, state:value}}  >
                                                        {value.title}
                                                    </NavLink>  
                                                </Menu.Item>
                                            )}
                                        </>
                                    ))}
                                    <Menu.Item key="defective_stock">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/defective_stock`}>
                                            Stocks Defective
                                        </NavLink>  
                                    </Menu.Item>
                                </>
                            )}
                        </SubMenu>
                    
                        
                        <SubMenu key="happy_data_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Happy Data List" class="parent">
                            <Menu.Item key="demo_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy`}>
                                    Demo Happy
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="complaint_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                    Complaint Happy
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="furniture_complaint_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/furniture_complaint_happy`}>
                                    Furniture Complaint Happy
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="follow_up_list" icon={!topMenu && <FeatherIcon icon="air play" />} title="Follow up List" class="parent">
                            <Menu.Item key="schedule_log">
                                <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log`}>
                                    Schedule Log
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="priority_log">
                                <NavLink onClick={toggleCollapsed} to={`${path}/priority_log`}>
                                    Priority Log
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="tele_sales_tab" icon={!topMenu && <FeatherIcon icon="air play" />} title="Tele Sales Tab" class="parent">
                            
                            { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0 && (
                                <>
                                    {getRequestTypesDetails.object_list.map((value,key) => (
                                        <>
                                            {(value.menu_type === 'tele-sales') && (
                                                <Menu.Item key={value.slug}>
                                                    <NavLink onClick={toggleCollapsed}  to={{ pathname:`${path}/follow/${value.slug}`, state:value}}>
                                                        {value.title}
                                                    </NavLink>  
                                                </Menu.Item>
                                            )}
                                        </>
                                    ))}
                                </>
                            )}


                            <Menu.Item key="mandatory_service">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mandatory_service`}>
                                    PAI Mandatory Service
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="upload_dump">
                            <NavLink onClick={toggleCollapsed} to={`${path}/upload_dump`}>
                                Upload Dump
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report 
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'queries' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/purchase`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="purchase"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/purchase`}>
                                Purchase Queries
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/available_report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="available_report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'customercare' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="call_center_report" icon={!topMenu && <FeatherIcon icon="list" />} title="Call Center Report">    
                            <Menu.Item key="live_calls">
                                <NavLink onClick={toggleCollapsed} to={`${path}/live_calls`}>
                                    Live Calls
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="signin_signout">
                                <NavLink onClick={toggleCollapsed} to={`${path}/signin_signout`}>
                                    SignIn SignOut
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="hours_activity_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/hours_activity_report`}>
                                    Hours Activity Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="call_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/call_report`}>
                                    Call Mointoring Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="call_barging_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/call_barging_report`}>
                                    Call Barging Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="voip_incoming_call">
                                <NavLink onClick={toggleCollapsed} to={`${path}/voip_incoming_call`}>
                                    VOIP Incoming Calls
                                </NavLink>
                            </Menu.Item>
                            {/* <Menu.Item key="call_report">
                                <NavLink onClick={toggleCollapsed} to={`${path}/call_report`}>
                                    Call Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="live_barging">
                                <NavLink onClick={toggleCollapsed} to={`${path}/live_barging`}>
                                    Live Barging Report
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="call_monitoring">
                                <NavLink onClick={toggleCollapsed} to={`${path}/call_monitoring`}>
                                   Call Mointoring Report
                                </NavLink>
                            </Menu.Item> */}
                        </SubMenu>

                        {/* <SubMenu key="lead" icon={!topMenu && <FeatherIcon icon="list" />} title="Lead">
                            <Menu.Item key="lead_outbound_call">
                                <NavLink onClick={toggleCollapsed} to={`${path}/lead_outbound_call`}>
                                    OutBound Call Report
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="lead_assign">
                                <NavLink onClick={toggleCollapsed} to={`${path}/lead_assign`}>
                                    Lead Assign
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="lead_agent_wise">
                                <NavLink onClick={toggleCollapsed} to={`${path}/lead_agent_wise`}>
                                    Agent wise Leads
                                </NavLink>
                            </Menu.Item>
                        </SubMenu> */}

                        <SubMenu key="auto_assign_lead" icon={!topMenu && <FeatherIcon icon="list" />} title="Auto Assign Lead">
                            <Menu.Item key="auto_assign_walkout">
                                <NavLink onClick={toggleCollapsed} to={`${path}/auto_assign_walkout`}>
                                    Walkout
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="auto_assign_demo_register">
                                <NavLink onClick={toggleCollapsed} to={`${path}/auto_assign_demo_register`}>
                                    Demo Register
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="auto_assign_demo_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/auto_assign_demo_happy`}>
                                    Demo Happy
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="auto_assign_complaint_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/auto_assign_complaint_happy`}>
                                    Complaint Happy
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>
                        
                        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="agent">
                            <NavLink onClick={toggleCollapsed} to={`${path}/agent`}>
                                Agent
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="setting_request_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_request_type`}>
                                    Request Type
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="setting_sub_request_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_sub_request_type`}>
                                    Sub Request Type
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="setting_break_details">
                                <NavLink onClick={toggleCollapsed} to={`${path}/setting_break_details`}>
                                    Break Details
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'maintenance' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_tickets">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_tickets`}>
                                Tickets
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="mt_employee">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_employee`}>
                                Maintenance Employee
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="mt_department">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_department`}>
                                    Department
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="mt_issues_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_issues_type`}>
                                    Type of Issues
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'quality' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_tickets">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_tickets`}>
                                Quality Leads
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="setting" icon={!topMenu && <FeatherIcon icon="settings" />} title="Setting">
                            <Menu.Item key="mt_department">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_department`}>
                                    Department
                                </NavLink>  
                            </Menu.Item>
                            <Menu.Item key="mt_issues_type">
                                <NavLink onClick={toggleCollapsed} to={`${path}/mt_issues_type`}>
                                    Type of Issues
                                </NavLink>  
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="available_report">
                            <NavLink onClick={toggleCollapsed} to={`${path}/available_report`}>
                                Available Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'maintenance_employee' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="mt_tickets">
                            <NavLink onClick={toggleCollapsed} to={`${path}/mt_tickets`}>
                                Tickets
                            </NavLink>
                        </Menu.Item>
                    </>
                )}
                
                { fullPath[2] === 'agent' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>
                        {agentStatus && agentStatus?.current_status === 'Available'  && (
                            <>
                                <Menu.Item icon={!topMenu && <FeatherIcon icon="check-square" />} key="check_customer">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/check_customer`}>
                                        Search / Register Customer
                                    </NavLink>
                                </Menu.Item>

                                {agentStatus?.lead_type === 'assign_leads'  && (

                                    <SubMenu key="assign_leads" icon={!topMenu && <FeatherIcon icon="air play" />} title="Assign Leads">
                                        <Menu.Item key="assign_leads_walkout">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_walkout`}>
                                                Walkout Leads
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="follow_mandatory_service">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/follow_mandatory_service`}>
                                                PAI Mandatory Service
                                            </NavLink>  
                                        </Menu.Item>
                                        { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0 && (
                                            <>
                                                {getRequestTypesDetails.object_list.map((value,key) => (
                                                    <>
                                                    {(value.type === 'assign-leads') && (
                                                        <Menu.Item key={key} >
                                                            <NavLink onClick={toggleCollapsed} to={{pathname:`${path}/follow/${value.slug}`, state: value  }}>
                                                                {value.title}
                                                            </NavLink>  
                                                        </Menu.Item>
                                                    )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </SubMenu>
                                    
                                )}

                                <SubMenu key="purchase_enquiry" icon={!topMenu && <FeatherIcon icon="globe" />} title="Purchase Enquiry">
                                    <Menu.Item key="purchase_enquiry">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/purchase_enquiry`}>
                                            Add Purchase Enquiry
                                        </NavLink>  
                                    </Menu.Item>
                                    <Menu.Item key="assign_leads_purchase">
                                        <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_purchase`}>
                                            Edit Purchase
                                        </NavLink>  
                                    </Menu.Item>
                                </SubMenu>

                                <Menu.Item icon={!topMenu && <FeatherIcon icon="phone-call" />} key="change_call">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/change_call`}>
                                        Change Call
                                    </NavLink>
                                </Menu.Item>

                                {agentStatus?.lead_type === 'dial_out'  && (

                                    <SubMenu key="dial_out" icon={!topMenu && <FeatherIcon icon="aperture" />} title="Dial Out">
                                         { getRequestTypesDetails && getRequestTypesDetails?.object_list && getRequestTypesDetails.object_list.length > 0 && (
                                            <>
                                                {getRequestTypesDetails.object_list.map((value,key) => (
                                                    <>
                                                    {(value.type === 'dial-out') && (
                                                        <Menu.Item key={key} >
                                                            <NavLink onClick={toggleCollapsed} to={{pathname:`${path}/follow/${value.slug}`, state: value  }}>
                                                                {value.title}
                                                            </NavLink>  
                                                        </Menu.Item>
                                                    )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                        <Menu.Item key="demo_happy">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/demo_happy`}>
                                                Demo Happy
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="complaint_happy">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/complaint_happy`}>
                                                Complaint Happy
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="furniture_complaint_happy">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/furniture_complaint_happy`}>
                                                Furniture Complaint Happy
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="schedule_log">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/schedule_log`}>
                                                Schedule Log
                                            </NavLink>  
                                        </Menu.Item>
                                        <Menu.Item key="priority_log">
                                            <NavLink onClick={toggleCollapsed} to={`${path}/priority_log`}>
                                                Priority log
                                            </NavLink>  
                                        </Menu.Item>
                                    </SubMenu>
                                       
                                )}

                                <Menu.Item icon={!topMenu && <FeatherIcon icon="check-square" />} key="search_reference">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/search_reference`}>
                                        Search Referenece No
                                    </NavLink>
                                </Menu.Item>

                                <Menu.Item icon={!topMenu && <FeatherIcon icon="phone-call" />} key="call_record">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/call_record`}>
                                        Call Record
                                    </NavLink>
                                </Menu.Item>
                            </>
                        )}

                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="check-square" />} key="check_customer">
                            <NavLink onClick={toggleCollapsed} to={`${path}/check_customer`}>
                                Check Customer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="phone-call" />} key="change_call">
                            <NavLink onClick={toggleCollapsed} to={`${path}/change_call`}>
                                Change Call
                            </NavLink>
                        </Menu.Item>
                        
                        <SubMenu key="assign_leads_walkout" icon={!topMenu && <FeatherIcon icon="airplay" />} title="Assign Leads">
                            <Menu.Item key="assign_leads_walkout">
                                <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_walkout`}>
                                    Walkout Data
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="assign_leads_purchase">
                                <NavLink onClick={toggleCollapsed} to={`${path}/assign_leads_purchase`}>
                                    Edit Purchase
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item icon={!topMenu && <FeatherIcon icon="globe" />} key="purchase_enquiry">
                            <NavLink onClick={toggleCollapsed} to={`${path}/purchase_enquiry`} className={fullPath[3] === 'purchase_enquiry' ? 'active' :null}>
                                Add Purchase 
                            </NavLink>
                        </Menu.Item>
                        
                        <SubMenu key="follow" icon={!topMenu && <FeatherIcon icon="aperture" />} title="Follow Up Leads">
                            <Menu.Item key="follow_demo_register">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_demo_register`}>
                                    Demo Register
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="follow_demo_happy">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_demo_happy`}>
                                    Demo Happy
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="follow_demo_complaint">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_demo_complaint`}>
                                    Complaint Happy
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="follow_customer_walkout">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_customer_walkout`}>
                                    Customer Walkout
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="follow_schedule_log">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_schedule_log`}>
                                    Scheduled Log
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="follow_priority_log">
                                <NavLink onClick={toggleCollapsed} to={`${path}/follow_priority_log`}>
                                    Priority Log
                                </NavLink>
                            </Menu.Item>
                        </SubMenu> */}

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )}

                { fullPath[2] === 'profile' &&(
                    <>
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="user" />} key="profile">
                            <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
                                My Profile
                            </NavLink>
                        </Menu.Item> */}
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="settings" />} key="setting">
                            <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
                                Account Setting
                            </NavLink>
                        </Menu.Item> */}
                        <Menu.Item icon={!topMenu && (<svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-key"
                            >
                            <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                            </svg>)} key="change_password">
                            <NavLink onClick={toggleCollapsed} to={`${path}/change_password`}>
                                Change Password
                            </NavLink>
                        </Menu.Item>
                        {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="bell" />} key="notification">
                            <NavLink onClick={toggleCollapsed} to={`${path}/notification`}>
                                Notification
                            </NavLink>
                        </Menu.Item> */}
                    </>
                )}


                {/* { fullPath[2] === 'feedback' &&(
                    <>
                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
                                        <FeatherIcon icon="home" />
                                    </NavLink>
                                )
                            } key="dashboard"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/ticket_list`}>
                                        <FeatherIcon icon="list" />
                                    </NavLink>
                                )
                            } key="ticket_list"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/ticket_list`}>
                                Tickets 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                !topMenu && (
                                    <NavLink className="menuItem-iocn" to={`${path}/report`}>
                                        <FeatherIcon icon="file-text" />
                                    </NavLink>
                                )
                            } key="report"
                        >
                            <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
                                Report
                            </NavLink>
                        </Menu.Item>
                    </>
                )} */}
                
            </Menu>
        </>
    );
}

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
};

export default MenuItems;
