import actions from './actions';

const { 
    EMPLOYEE_DASHBOARD_DATA_BEGIN, EMPLOYEE_DASHBOARD_DATA_SUCCESS, EMPLOYEE_DASHBOARD_DATA_ERR, 
    EMPLOYEE_DATA_BEGIN, EMPLOYEE_DATA_SUCCESS, EMPLOYEE_DATA_ERR, 
    EMPLOYEE_USER_TYPE_DATA_BEGIN, EMPLOYEE_USER_TYPE_DATA_SUCCESS, EMPLOYEE_USER_TYPE_DATA_ERR, EMPLOYEE_USER_TYPE_DATA_RESET,
    EMPLOYEE_LOGIN_STATUS_BEGIN, EMPLOYEE_LOGIN_STATUS_SUCCESS, EMPLOYEE_LOGIN_STATUS_ERR, EMPLOYEE_LOGIN_STATUS_RESET,
    EMPLOYEE_STATUS_BEGIN, EMPLOYEE_STATUS_SUCCESS, EMPLOYEE_STATUS_ERR, 
    EMPLOYEE_PASSWORD_BEGIN, EMPLOYEE_PASSWORD_SUCCESS, EMPLOYEE_PASSWORD_ERR, EMPLOYEE_PASSWORD_RESET,
    EMPLOYEE_REPORT_DATA_BEGIN, EMPLOYEE_REPORT_DATA_SUCCESS, EMPLOYEE_REPORT_DATA_ERR,
    EMPLOYEE_REPORT_SAVE_DATA_BEGIN, EMPLOYEE_REPORT_SAVE_DATA_SUCCESS, EMPLOYEE_REPORT_SAVE_DATA_ERR, 
    EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN, EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS, EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR, 
    EMPLOYEE_MANUAL_DATA_BEGIN, EMPLOYEE_MANUAL_DATA_SUCCESS, EMPLOYEE_MANUAL_DATA_ERR,
    EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN,EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS,EMPLOYEE_CREATE_CUSTOM_DATA_ERR,EMPLOYEE_CREATE_CUSTOM_DATA_RESET,
    EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN, EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS, EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_ERR, EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_RESET,
    VENDOR_PINCODE_DATA_BEGIN, VENDOR_PINCODE_DATA_SUCCESS, VENDOR_PINCODE_DATA_ERR,

} = actions;

const initialStateFilter = { data: [], loading: false, error: null, };
const initialState = { data: null, loading: false, error: null };
const initialStateDashboard = { data: [], start:null, end:null, loading: false, error: null, };

const employeeDashbaordReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case EMPLOYEE_DASHBOARD_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case EMPLOYEE_DASHBOARD_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case EMPLOYEE_DASHBOARD_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const employeeReducers = (state = initialStateFilter, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case EMPLOYEE_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error:null,
                loading: false,
            };
        case EMPLOYEE_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const updateEmployeeUserTypeReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case EMPLOYEE_USER_TYPE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };

        case EMPLOYEE_USER_TYPE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };

        case EMPLOYEE_USER_TYPE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case EMPLOYEE_USER_TYPE_DATA_RESET:
            return initialState;

        default:
            return state;
    }
};

const updateEmployeeLoginStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_LOGIN_STATUS_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case EMPLOYEE_LOGIN_STATUS_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error:null,
                loading: false,
            };
        case EMPLOYEE_LOGIN_STATUS_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
        case EMPLOYEE_LOGIN_STATUS_RESET:
            return initialStateFilter;
            
        default:
            return state;
    }
};

const updateEmployeeStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_STATUS_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case EMPLOYEE_STATUS_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
                error:null,
            };
        case EMPLOYEE_STATUS_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
            
        default:
            return state;
    }
};

const updateEmployeePasswordReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_PASSWORD_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case EMPLOYEE_PASSWORD_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                loading: false,
                error:null,
            };
        case EMPLOYEE_PASSWORD_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
        
        case EMPLOYEE_PASSWORD_RESET:
            return initialStateFilter;
            
        default:
            return state;
    }
};

const employeeManualSyncReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_MANUAL_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EMPLOYEE_MANUAL_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EMPLOYEE_MANUAL_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const employeeManualCreateReducers = (state=initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EMPLOYEE_CREATE_CUSTOM_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case EMPLOYEE_CREATE_CUSTOM_DATA_RESET:
            return initialState;

        default:
            return state;
    }
}

const employeeManualEditReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error:null,
                loading: false,
            };
        case EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
        case EDIT_EMPLOYEE_CREATE_CUSTOM_DATA_RESET:
            return initialStateFilter;
            
        default:
            return state;
    }
};

const vendorPincodeListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VENDOR_PINCODE_DATA_BEGIN:
            return {
                ...initialStateFilter,
                loading: true,
            };
        case VENDOR_PINCODE_DATA_SUCCESS:
            return {
                ...initialStateFilter,
                data,
                error:null,
                loading: false,
            };
        case VENDOR_PINCODE_DATA_ERR:
            return {
                ...initialStateFilter,
                error: err,
                data:null,
                loading: false,
            };
        default:
            return state;
    }
};

const employeeReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EMPLOYEE_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EMPLOYEE_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const employeeReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EMPLOYEE_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EMPLOYEE_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const employeeReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export {
    employeeDashbaordReducers, employeeReducers, updateEmployeeUserTypeReducers, 
    updateEmployeeLoginStatusReducers, updateEmployeeStatusReducers, updateEmployeePasswordReducers, employeeReportReducers, 
    employeeReportSaveReducers, employeeReportDownloadReducers, employeeManualSyncReducers, 
    employeeManualCreateReducers,employeeManualEditReducers,vendorPincodeListReducers
};
